<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "./components/ArticleFrame.vue";

export default {
  name: "TenBestPracticesForWritingFromHome",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="TenBestPracticesForWritingFromHome">
      <SuawParagraph
        text="The COVID-19 pandemic has left few of us unaffected. For those of you who suddenly find yourselves in the position of having to work from home, we’ve compiled a list of best practices tailored to help you stay on track with your writing. Remember that creative work is a valuable and important outlet, and your writing practice can be a time in your day that allows you to reflect, escape, and feel productive."
      />
      <SuawHeading level="3" content="1. Get Organized" />
      <SuawParagraph text="Spend your first day or two of working from home getting organized." />
      <SuawParagraph
        text="<ul><li>If possible, designate an area of your home exclusively for your writing. <a target='_blank' title='https://www.kentucky.com/news/business/article211602939.html' href='https://www.kentucky.com/news/business/article211602939.html'>An appropriate environment</a> can improve your focus, and over time, your brain will begin to associate this chosen environment with the right headspace for writing.</li><li>Set up a schedule to structure your workdays and/or school days. This could mean adapting your usual schedule to your situation at home, or it could mean setting up a new routine based on the others in your household. If possible, try to establish a pattern by waking up, getting dressed, breaking for lunch, and ending your workday at the same times as you would normally.</li><li>Make sure that you and your family members have easy access to the tools needed to write, work, and study remotely, like noise-canceling headphones, video conferencing software, and virtual learning platforms.</li><li>If you have children who’ve had to switch to virtual learning instead of going to school, familiarize yourself with their remote learning plan, which can vary from school to school. With this plan in mind, work together with your child to set up their own schedule, and set aside specific areas of the house where each member of your family can get their work done.</li><li>Consider that some people—kids included—work better in a “coffee shop” environment. Working together quietly on your respective tasks at the kitchen table may be a solution. Create that Shut Up &amp; Write! atmosphere at home!</li></ul>"
      />
      <SuawHeading level="3" content="2. Keep Your Physical Health in Mind" />
      <SuawParagraph
        text="If possible, ensure that you have an <a target='_blank' title='https://ergonomictrends.com/creating-perfect-ergonomic-workspace-ultimate-guide/' href='https://ergonomictrends.com/creating-perfect-ergonomic-workspace-ultimate-guide/'>ergonomic setup</a> for your writing space."
      />
      <SuawParagraph
        text="Try out these <a target='_blank' title='https://www.healthline.com/health/deskercise' href='https://www.healthline.com/health/deskercise'>gentle exercises</a> from the comfort of your desk at regular intervals throughout the day to stave off neck and hand problems."
      />
      <SuawParagraph
        text="<ul><li>If you’re able, get outside for a walk or a run every day, but be sure to maintain the <a target='_blank' title='https://www.uchealth.org/today/what-is-social-distancing-you-can-go-outside/' href='https://www.uchealth.org/today/what-is-social-distancing-you-can-go-outside/'>recommended distance of six feet from others</a> when you’re outside.</li><li><a target='_blank' title='https://www.youtube.com/watch?v=eZw4Ga3jg3E&amp;feature=youtu.be' href='https://www.youtube.com/watch?v=eZw4Ga3jg3E&amp;feature=youtu.be'>Wash your hands</a> and <a target='_blank' title='https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fcoronavirus%2F2019-ncov%2Fprevent-getting-sick%2Fcleaning-disinfection.html' href='https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/disinfecting-your-home.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fcoronavirus%2F2019-ncov%2Fprevent-getting-sick%2Fcleaning-disinfection.html'>disinfect your belongings</a> immediately after returning home.</li></ul>"
      />
      <SuawHeading level="3" content="3. Set an “Implementation Intention” to Keep Yourself Accountable" />
      <SuawParagraph
        text="Studies show that creating a plan—or an “<a target='_blank' title='https://jamesclear.com/implementation-intentions' href='https://jamesclear.com/implementation-intentions'>Implementation Intention</a>”—for when and where you will do a habit greatly increases the likelihood that you will follow through. Decide ahead of time on a set time or location when you will get your writing done. You can also use <a target='_blank' title='https://jamesclear.com/habit-stacking' href='https://jamesclear.com/habit-stacking'>habit stacking</a> to time your writing habit based around an existing habit. For example:"
      />
      <SuawParagraph
        text="<ul><li>At noon, I will write for one hour.</li><li>When it’s 6pm, I will sign off from my work computer for the day.</li><li>After I finish my daily run, I will write 1000 words of my novel.</li><li>Before I get into bed at night, I will write down three things I did today.</li></ul>"
      />
      <SuawHeading level="3" content="4. Intentionally Create Space for Calmness" />
      <SuawParagraph
        text="You can use apps like <a target='_blank' title='https://www.headspace.com/headspace-meditation-app' href='https://www.headspace.com/headspace-meditation-app'>Headspace</a> or <a target='_blank' title='https://www.calm.com/' href='https://www.calm.com/'>Calm</a> for guided meditations throughout the day. If you don’t enjoy meditation, you can find a few moments of silence by intentionally sipping your herbal tea, water, or healthful drink of your choice at any time of the day. For this to work as intended, make sure you’re not distracted by other tasks as you sip."
      />
      <SuawHeading level="3" content="5. Allow Yourself to Get Inspired" />
      <SuawParagraph
        text="Don’t be afraid to let yourself feel inspired by challenging times. Whether you’re writing about your experiences, turning reflections into pieces of memoir, or just working on a dystopian novel, writing can be a great way to channel what you’re going through into something positive and productive. Let yourself take stock of what you are most grateful for, and remember that anything that gets you writing is a good thing."
      />
      <SuawHeading level="3" content="6. Consider “Creative Cross-Training”" />
      <SuawParagraph
        text="If you find yourself with a surplus of extra time at home, consider doing some “creative cross-training.” Journal about your experience, make weird art, get yourself dancing, pick up that long-neglected knitting project, start a podcast—the options are endless. Have fun with it!"
      />
      <SuawParagraph
        text="Now can also be a great time to dive into reading books or listening to audiobooks so that you can get further inspiration for your own creative work."
      />
      <SuawHeading level="3" content="7. Be Kind To Yourself" />
      <SuawParagraph
        text="You may or may not have the extra time and capacity to take on more creative tasks while at home. Be kind with yourself if you’re not writing or creating as much as usual."
      />
      <SuawHeading level="3" content="8. Lean On Your Community" />
      <SuawParagraph
        text="Decide how you’re going to find community for your writing. Whether it’s video-chatting with your writing buddies, attending online events, or participating in our next <a target='_blank' title='https://www.shutupwrite.com/challenges' href='https://www.shutupwrite.com/challenges'>Shut Up &amp; Write! Challenge</a>, we all know how much our community supports and motivates us. It even has the potential to bring out the best of our creative selves."
      />
      <SuawHeading level="3" content="9. Create Healthy Boundaries Around Media Consumption" />
      <SuawParagraph
        text="Consider setting <a target='_blank' title='https://www.calnewport.com/blog/2020/03/13/on-digital-minimalism-and-pandemics/' href='https://www.calnewport.com/blog/2020/03/13/on-digital-minimalism-and-pandemics/'>specific times</a> where you’ll check the news or go online. For example, catch up on news for no more than 15 minutes at the beginning of each day. Apps like <a target='_blank' title='https://play.google.com/store/apps/details?id=master.app.screentime&amp;hl=en_US' href='https://play.google.com/store/apps/details?id=master.app.screentime&amp;hl=en_US'>Screen Time</a> can help you keep your daily news consumption to an amount that is reasonable for you."
      />
      <SuawParagraph text="Consider picking a time to “log off” for the day and go back into the analog world." />
      <SuawParagraph
        text="Talk to your family about your boundaries and ask for support in protecting your daily rituals around news consumption, as well as your intentions around working and writing each day."
      />
      <SuawHeading level="3" content="10. Set Your Intentions for Tomorrow" />
      <SuawParagraph
        text="As you finish up a writing session, and while you’re still in the creative headspace, make a note for yourself on a good starting point for you to work on next time. What questions to think about, what section you should focus on, etc. This will help you pick up where you left off tomorrow."
      />
      <SuawParagraph
        text="We hope these best practices are helpful as you adjust to working and writing from home. If you have any questions or feedback for us about how we can support you, please email us at questions@shutupwrite.com"
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
